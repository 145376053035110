import * as React from 'react';
// import { useAuth0 } from "@auth0/auth0-react";

// import { Auth0Provider } from "@auth0/auth0-react";

import Paper from '@mui/material/Paper';

const Login = () => {

    // const { loginWithRedirect } = useAuth0();

    return (
        <Paper>
            <button onClick={() => {}}>Button</button>
        </Paper>
    )
}

export default Login;